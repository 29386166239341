<template>
  <span
    v-if="article.CustomWriter"
    class="font-bold opacity-50 line-clamp-1 mr-2"
    >{{ article.CustomWriter }}
  </span>
  <template v-else-if="article.Writers">
    <div class="flex flex-wrap gap-y-1">
      <template v-for="(writer, index) in shownWriters" :key="index">
        <div v-if="index > 0" class="font-bold opacity-50 mx-1 leading-5">
          {{ $t('And') }}
        </div>
        <div class="flex">
          <NuxtImg
            v-if="['opinion', 'politicalSpeech', 'analysis'].includes(type)"
            :width="20"
            :height="20"
            class="w-5 h-5 mr-1 bg-black bg-opacity-10 rounded-full overflow-hidden bg-center bg-cover shrink-0"
            :alt="writer.Name"
            :src="getWriterImageSrc(writer)"
            :loading="loadingString"
            :preload="preload"
          />
          <div
            class="font-bold opacity-50 leading-5"
            :class="{
              'mr-2': shownWriters && index === shownWriters?.length - 1,
            }"
          >
            {{ writer.Name }}
          </div>
        </div>
      </template>
    </div>
  </template>
</template>

<script setup lang="ts">
import type {
  ContentArticle,
  Writer,
  Person,
} from '~/typesManual/content_api/article'

const siteConfig = useSiteConfig()

const props = defineProps<{
  article: ContentArticle
  type: string
  lazy: boolean
  preload: boolean
}>()

const shownWriters = computed(() => {
  if (false) {
    return props.article.Writers?.slice(0, 1)
  }

  return props.article.Writers?.slice(0, 2)
})

const loadingString = computed(() => (props.lazy ? 'lazy' : 'eager'))

function isWriter(object: Writer | Person): object is Writer {
  return (object as Writer)?.Type === 'writer'
}

const getWriterImageSrc = (writer: Writer | Person) => {
  if (!writer.Image) {
    return ''
  }

  if (writer.Image.startsWith('https://')) {
    return writer.Image
  } else if (isWriter(writer)) {
    return `https://${siteConfig.legacyurl}/images/writers/${appendToFilename(
      writer.Image,
      '-2-48'
    )}`
  } else if (writer.MasterId && writer.MasterId !== 0) {
    return `https://legacy.altinget.dk/images/person/${writer.MasterId}/${writer.Image}`
  }

  return `https://${siteConfig.legacyurl}/images/person/${writer.RecordId}/${writer.Image}`
}
</script>
